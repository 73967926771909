import React, { useEffect } from "react";
import styled from "@emotion/styled";
import AboutPic from "../../../Media/About/About.jpg";
import Bg1 from "../../../Media/About/bg1.png";
//Gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function About() {
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ".itemAnimate",
      start: "top 95%",
      end: "bottom top",
      duration: 1.25,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto",
      scrub: 1,
      onEnter: () => {
        gsap.to(".itemAnimate", {
          x: 0,
          duration: 1,
          opacity: 1,
        });
      },
      onLeave: () => {
        gsap.to(".itemAnimate", {
          x: -100,
          duration: 1,
          opacity: 0,
        });
      },
      onEnterBack: () => {
        gsap.to(".itemAnimate", {
          x: 0,
          duration: 1,
          opacity: 1,
        });
      },
      onLeaveBack: () => {
        gsap.to(".itemAnimate", {
          x: 100,
          duration: 1,
          opacity: 0,
        });
      },
    });
	//
    // ScrollTrigger.create({
    //   trigger: ".itemAnimate2",
    //   start: "top 95%",
    //   end: "bottom top",
    //   duration: 1.25,
    //   autoAlpha: 1,
    //   ease: "expo",
    //   overwrite: "auto",
    //   scrub: 1,
    //   onEnter: () => {
    //     gsap.to(".itemAnimate2", {
    //       x: 0,
    //       duration: 1,
    //       opacity: 1,
    //     });
    //   },
    //   onLeave: () => {
    //     gsap.to(".itemAnimate2", {
    //       x: 100,
    //       duration: 1,
    //       opacity: 0,
    //     });
    //   },
    //   onEnterBack: () => {
    //     gsap.to(".itemAnimate2", {
    //       x: 0,
    //       duration: 1,
    //       opacity: 1,
    //     });
    //   },
    //   onLeaveBack: () => {
    //     gsap.to(".itemAnimate2", {
    //       x: -100,
    //       duration: 1,
    //       opacity: 0,
    //     });
    //   },
    // });
  }, []);

  return (
    <StyledAbout data-scroll-section id="AboutMe">
      <div className="left itemAnimate2"></div>
      <div className="right">
        <h1 className="itemAnimate">
      Every <b>business</b> has its own specific and well-ordered process, and the <b>success</b> of the business is determined by the correctness and completeness of this process.
      Processes are based on specific <b>rules or know-how</b>.<br></br>
      <b>Businesses</b> are constantly faced with the challenges of improving, changing and innovating their operations as they continuously implement processes.
        </h1>
      </div>
    </StyledAbout>
  );
}

const StyledAbout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding-bottom: 15vh;
  padding-top: 5vh;
  background: url(${Bg1});


  .left {
	width: 40%;
	height: 350px;

	background-position: center;
	background-size: cover;
	transform: translateX(-5vw);
  }

  .right {
	position: absolute;
	width: 50%;
	left: 40%;

	background: rgb(246 246 246 / 80%);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
	padding: 2vh;
	border: 1px solid rgb(221 221 226);


	h1 {
	  color: var(--black-color);
	  font-family: Inter, sans-serif;
	  font-weight: 300;
	  font-size: 20px;
	  line-height: 1.8rem;
	  transform: translateX(8vw);
	  opacity: 0;
	}
  }

  @media (max-width: 768px) {
	flex-direction: column;
	justify-content: initial;
	align-items: initial;

	.left {
	  width: 100%;
	}

	.right {


		width: 100%;
	  margin-left: 0px;
	  padding: 10px;
	  position: static;


	  h1 {
		font-size: 15px;
		text-align: left;

	  }
	}
  }
`;

export default About;
