import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components'
import locomotiveScroll from "locomotive-scroll";
//importSections
import {SkillsTitles,Header,About,Skills,UserSegment,SectionTitle,Statistic,Footer,SectionTitleMarginTop} from '../Components/index'
import Navbar from '../Components/Navbar/Navbar'
//FramerMotion
import { motion } from 'framer-motion'
//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Advantage from "../Components/Main_Components/Advantage/Advantage";
gsap.registerPlugin(ScrollTrigger);

function Main() {

const refScroll = useRef(null);

useEffect(()=>{
  const Container = refScroll.current;
},[])
  return (
    <motion.div
    id='main-container'
    initial ={{opacity:0}}
    animate= {{opacity:1, transition :{delay: 0.1,duration:1.3}}}
    exit={{ opacity:0 ,transition :{delay: 0.1,duration:0.2}}}
    >
        <Navbar/>
        <Header/>
        <SkillsTitles/>
		<SectionTitle text='Business and Process'/>
        <About/>

		<SectionTitle text='Bizpro advantages'/>
		<Advantage/>
        <UserSegment/>
		<SectionTitleMarginTop text='Value for you'/>

		<Statistic/>

		<SectionTitle text='Contact'/>


        <Footer/>
    </motion.div>
  )
}

export default Main
