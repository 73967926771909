import React from 'react'
import jQuery from 'jquery'
import styled from 'styled-components'
import $ from 'jquery'
import Morocco from '../../../Media/Logo/Morocco.png'

$(".contactform").on("submit", function() {
	$(".output_message").text("Sending...");

	var form = $(this);
	$.ajax({
		url: form.attr("action"),
		method: form.attr("method"),
		data: form.serialize(),
		success: function(result) {
			if (result === "success") {
				$(".form-inputs").css("display", "none");
				$(".box p").css("display", "none");
				$(".contactform").find(".output_message").addClass("success");
				$(".output_message").text("Message Sent!");
			} else {
				$(".tabs-container").css("height", "440px");

				$(".contactform").find(".output_message").addClass("error");
				$(".output_message").text("Error Sending!");
			}
		}
	});

	return false;
});

function Footer() {
  return (
    <StyledFooter data-scroll-section>

		<div className="contact" id="ContactBizpro">
		<section className="main-content revealator-slideup revealator-once revealator-delay1">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-4">

						<p className="open-sans-font custom-span-contact position-relative">
							<i className="fa fa-envelope-open position-absolute"></i>
							<span className="d-block">E-Mail:</span>hello@iotech.mn
						</p>
						<p className="open-sans-font custom-span-contact position-relative">
							<i className="fa fa-phone-square position-absolute"></i>
							<span className="d-block">Call</span>+976 88880417
						</p>
					</div>

					<div className="col-12 col-lg-8">
						<form className="contactform" method="post"
							  action="#">
							<div className="contactform">
								<div className="row">
									<div className="col-12 col-md-4">
										<input type="text" name="name" placeholder="John Doe" required></input>
									</div>
									<div className="col-12 col-md-4">
										 <input type="email" name="email" placeholder="john@doe.mn" required></input>
									</div>
									<div className="col-12 col-md-4">
										<input type="text" name="subject" placeholder="Greeting"></input>
									</div>
									<div className="col-12">
										<textarea name="message" placeholder="Hello IO Technology" required></textarea>
										<button type="submit" className="btn btn-contact">Send Message</button>
									</div>
									<div className="col-12 form-message">
										<span
											className="output_message text-center font-weight-600 text-uppercase"></span>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

		</section>
		</div>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
 height : auto;
 display : flex;
 justify-content :center;
 align-items : flex-end;


  .btn {
	box-shadow: none !important;
	border-radius: 12px;
	font-weight: 500;
	height: 46px;
	line-height: 46px;
	font-size: 15px;
	text-transform: uppercase;
	color: #fff;
	padding: 0 40px;
	outline: none !important;
	overflow: hidden;
	display: inline-block;
	position: relative;
	font-family: Inter, sans-serif;
	letter-spacing: .5px;
	background: var(--black-color);
	border: 0;
  }

  .btn,
  .btn:hover,
  .btn:focus {
	color: #fff;
	text-decoration: none;
  }

 h2{
   color : white;
   font-family : var(--font-tertiary);
   display : flex;
   justify-content :center;
   align-items : center;
   margin-bottom : 15px;
   font-size : 23px;

   .Morocco{
     height : 22px;
   }
 }


  .contact {


	font-family: Inter,SansSerif;

    .main-content {
      padding-bottom: 85px;
	  padding-top: 85px;
    }
  }

  /* Left Side */

  .contact .custom-span-contact {
	font-weight: 600;
	padding-left: 50px;
	line-height: 21px;
	padding-top: 5px;
  }

  .contact .custom-span-contact i {
	left: 0;
	top: 10px;
	font-size: 33px;
  }

  .contact .custom-span-contact i.fa-phone-square {
	font-size: 39px;
	top: 7px;
  }

  .contact .custom-span-contact span {
	text-transform: uppercase;
	opacity: .8;
	font-weight: 400;
  }

  .contact ul.social {
	margin-left: -5px;
  }

  .contact ul.social li {
	display: inline-block;
  }

  .contact ul.social li a {
	display: inline-block;
	height: 40px;
	width: 40px;
	line-height: 42px;
	text-align: center;
	color: #fff;
	transition: .3s;
	font-size: 17px;
	margin: 0 6px;
	background: #2b2a2a;
	border-radius: 50%;
  }

  /* Form */

  .contact .contactform input[type=text],
  .contact .contactform input[type=email] {
	border: 1px solid #dddde1;
	width: 100%;
	background: #f6f6f6;
	color: var(--black-color);
	padding: 11px 26px;
	font-family: Inter,sans-serif;
	margin-bottom: 30px;
	border-radius: 12px;
	outline: none !important;
	-webkit-transition: .3s;
	transition: .3s;
  }

  .contact .contactform textarea {
	border: 1px solid #dddde1;
	background: #f6f6f6;
	color: var(--black-color);
	width: 100%;
	padding: 12px 26px;
	margin-bottom: 20px;
	height: 160px;
	overflow: hidden;
	border-radius: 12px;
	outline: none !important;
	transition: .3s;
  }

  .contact .contactform .form-message {
	margin: 24px 0;
  }

  .contact .contactform .output_message {
	display: block;
	color: #000;
	height: 46px;
	line-height: 46px;
	border-radius: 30px;
  }

  .contact .contactform .output_message.success {
	background: #28a745;
  }

  .contact .contactform .output_message.success:before {
	font-family: FontAwesome;
	content: "\\f14a";
	padding-right: 10px;
  }

  .contact .contactform .output_message.error {
	background: #dc3545;
  }

  .contact .contactform .output_message.error:before {
	font-family: FontAwesome;
	content: "\\f071";
	padding-right: 10px;
  }


  .btn:before {
	font: 15px/1.2em FontAwesome;
	color: #fff;
	position: absolute;
	height: 100%;
	right: 25px;
	top: 50%;
	margin-top: -9px;
	transition: all 0.3s;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: translateX(-20px);
	transform: translateX(-20px);
  }

  .btn.btn-about:before {
	content: "\\f007";
  }

  .btn.btn-portfolio:before {
	content: "\\f07c";
	margin-top: -8px;
  }

  .btn.btn-preview:before {
	content: "\\f08e";
  }

  .btn.btn-download:before {
	content: "\\f019";
  }

  .btn.btn-contact:before {
	content: "\\f1d8";
  }

  .btn span {
	position: relative;
	display: block;
	transition: all 0.3s;
  }

  .btn:hover span {
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px);
  }

  .btn:hover:before {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: translateX(0);
	transform: translateX(0);
  }

`

export default Footer
