import React, { useEffect } from "react";
import styled from "@emotion/styled";
import AboutPic from "../../../Media/About/About.jpg";
import Bg2 from "../../../Media/Advantage/bg2.png";
//Gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function About() {
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ".itemAnimate1",
      start: "top 95%",
      end: "bottom top",
      duration: 1.25,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto",
      scrub: 1,
      onEnter: () => {
        gsap.to(".itemAnimate1", {
          x: 0,
          duration: 1,
          opacity: 1,
        });
      },
      onLeave: () => {
        gsap.to(".itemAnimate1", {
          x: -100,
          duration: 1,
          opacity: 0,
        });
      },
      onEnterBack: () => {
        gsap.to(".itemAnimate1", {
          x: 0,
          duration: 1,
          opacity: 1,
        });
      },
      onLeaveBack: () => {
        gsap.to(".itemAnimate1", {
          x: 100,
          duration: 1,
          opacity: 0,
        });
      },
    });

  }, []);

  return (
    <StyledAbout data-scroll-section id="Advantage">
		<div className="left itemAnimate2"></div>
		  <div className="right">
			<h1 className="itemAnimate1">
      <b>BizPro</b> is an internet and cloud-based system that can be used seamlessly on all operating systems.
				<br/>
        <b>SOLID architecture and User interface</b> designed to develop any business process into an error-free system in a short time. It is designed to be suitable for use by companies of any size.
				<br/>
        Developed with internationalization in mind, the system is currently available in <b>15</b> languages.
			</h1>
		  </div>
    </StyledAbout>
  );
}

const StyledAbout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding-bottom: 25vh;
  padding-top: 5vh;
  background: url(${Bg2});
  background-size: cover;

  .left {
	width: 40%;
	height: 350px;
	background-position: center;
	background-size: contain;
	transform: translateX(-5vw);
  }

  .right {
	position: absolute;
	width: 50%;
	background: rgb(246 246 246 / 90%);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
	padding: 2vh;
	border: 1px solid rgb(221 221 226);
	left: 10%;

	h1 {
	  color: var(--black-color);
	  font-family: Inter, sans-serif;
	  font-weight: 300;
	  font-size: 20px;
	  opacity: 0.7;
	  line-height: 1.8rem;
	  transform: translateX(8vw);
	  opacity: 0;
	}
  }

  @media (max-width: 768px) {
	flex-direction: column;
	justify-content: initial;
	align-items: initial;

	.left {
	  width: 100%;
	}

	.right {


		width: 100%;
	  margin-left: 0px;
	  padding: 10px;
	  position: static;


	  h1 {
		font-size: 15px;
		text-align: left;

	  }
	}
  }
`;

export default About;
