import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
//LightBox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
//media
import Project1Gif from "../../../Media/Projects/Project1.gif";
import Project1 from "../../../Media/Projects/Project1.jpg";
import Project2Gif from "../../../Media/Projects/Project2.gif";
import Project2 from "../../../Media/Projects/Project2.jpg";
import Project3Gif from "../../../Media/Projects/Project3.gif";
import SolutionBg from "../../../Media/UserSegment/solution_bg.png";
import Circle from "../../../Media/Header/circle.png";
import Square from "../../../Media/Header/square.png";
import RightArrow from "../../../Media/Header/right_arrow.png";
import LineImage from "../../../Media/UserSegment/line-image.png";
import {Portfolio, abundeez, Wydad, Conveasy} from "../Skills/SkillsData";
import {Data} from "./DesignsData";
//icons
import {FaRegEye, FaGithub, FaGithubSquare, FaWrench} from "react-icons/fa";
import {motion} from "framer-motion";
import DesignCrowd from "../../../Media/Logo/DesignCrowd.png";
import Fiverr from "../../../Media/Logo/Fiverr.png";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
//MaterialUI
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

//Gsap
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.defaults({ease: "power3"});
gsap.set(".item", {y: 10});
gsap.registerPlugin(ScrollTrigger);


function WorksContainer() {
	//Filter
	const [filtered, setFiltered] = useState(Data);
	const [ActiveTag, setActiveTag] = useState("All");
	//lightbox
	const [isOpen, setIsOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState();
	//Refs
	const LineIcon1 = useRef(null);
	const LineIcon2 = useRef(null);
	const LineIcon3 = useRef(null);
	const GDWorksTitle = useRef(null);

	useEffect(() => {
		const el1 = LineIcon1.current;

		const elGraphicDesign = GDWorksTitle.current;


		gsap.to(el1, {
			scrollTrigger: {
				trigger: el1,
				start: "top bottom",
				end: "bottom top",
				scrub: 0.5,
			},
			y: "0%",
			ease: "none",
		});

		gsap.to(elGraphicDesign, {
			scrollTrigger: {
				trigger: elGraphicDesign,
				start: "top bottom",
				end: "bottom top",
				scrub: 0.1,
			},
			y: "100%",
			ease: "none",
		});

		ScrollTrigger.batch(".itemAnimation", {
			interval: 0.1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
			batchMax: 3, // maximum batch size (targets)
			onEnter: (batch) =>
				gsap.to(batch, {autoAlpha: 1, stagger: 0.15, overwrite: true}),
			onLeave: (batch) => gsap.set(batch, {autoAlpha: 0, overwrite: true}),
			onEnterBack: (batch) =>
				gsap.to(batch, {autoAlpha: 1, stagger: 0.15, overwrite: true}),
			onLeaveBack: (batch) =>
				gsap.set(batch, {autoAlpha: 0, overwrite: true}),
			// you can also define things like start, end, etc.
		});

		ScrollTrigger.batch(".DevWorkItemAnimate", {
			interval: 0.1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
			batchMax: 3, // maximum batch size (targets)
			onEnter: (batch) =>
				gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true}),
			onLeave: (batch) =>
				gsap.set(batch, {autoAlpha: 0, y: -100, overwrite: true}),
			onEnterBack: (batch) =>
				gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true}),
			onLeaveBack: (batch) =>
				gsap.set(batch, {autoAlpha: 0, y: 100, overwrite: true}),
			// you can also define things like start, end, etc.
		});



		ScrollTrigger.create({
			trigger: ".Section",
			start: "top 95%",
			end: "bottom top",
			duration: 1.25,
			autoAlpha: 1,
			ease: "expo",
			overwrite: "auto",
			scrub: 1,
			onEnter: () => {
				gsap.to(".Section", {
					y: 0,
					duration: 1,
				});
			},
			onLeave: () => {
				gsap.to(".Section", {
					y: -100,
					duration: 1,
				});
			},
			onEnterBack: () => {
				gsap.to(".Section", {
					y: 0,
					duration: 1,
				});
			},
			onLeaveBack: () => {
				gsap.to(".Section", {
					y: 100,
					duration: 1,
				});
			},
		});

		ScrollTrigger.create({
			trigger: ".WorkItemAnimation",
			start: "top bottom",
			end: "bottom top",
			duration: 1,
			autoAlpha: 1,
			ease: "expo",
			overwrite: "auto",
			scrub: 1,
			onEnter: () => {
				gsap.to(".WorkItemAnimation", {
					y: 0,
					duration: 1,
					opacity: 1,
				});
			},
			onLeave: () => {
				gsap.to(".WorkItemAnimation", {
					y: -100,
					duration: 1,
					opacity: 0,
				});
			},
			onEnterBack: () => {
				gsap.to(".WorkItemAnimation", {
					y: 0,
					duration: 1,
					opacity: 1,
				});
			},
			onLeaveBack: () => {
				gsap.to(".WorkItemAnimation", {
					y: 100,
					duration: 1,
					opacity: 0,
				});
			},
		});


		let select = s => document.querySelector(s),
			selectAll = s =>  document.querySelectorAll(s),
			mainSVG = select('#mainSVG')

		gsap.set('svg', {
			visibility: 'visible'
		})

		let tl = gsap.timeline({repeat: -1});
		tl.to('#allBoxes rect', {
			transformOrigin: '107% 50%',
			rotation: gsap.utils.wrap([180, -180]),
			stagger: {
				each: 0.14,
			}
		})
			.to('#allBoxes', {
				x: -112,
				duration: tl.recent().duration(),
				ease: 'linear'
			}, 0)

		gsap.set('#ref', {
			scaleY: -1,
			transformOrigin: '50% 50%'
		})


	}, []);

	useEffect(() => {
		const Filtered =
			ActiveTag === "All"
				? Data
				: Data.filter((item) => {
					return item.tag === ActiveTag;
				});
		setFiltered(Filtered);
	}, [ActiveTag]);

	const handleChange = (event, value) => {
		setActiveTag(value.name);
	};

	return (
		<StyledContainer data-scroll-section id="UserSegment">

			<div className="UserSegmentContainer Section">
				<h1>Who needs Bizpro?</h1>

				<UserSegmentCard
					prv={Square}
					title="IT start-ups and traditional companies"
					desc="Organizations with unstable operations and a small number of employees who find it difficult to purchase additional systems. Also for people with innovative ideas to develop a new software start-up."
				/>

				<div className="horizontal_dotted_line"/>
				<UserSegmentCard
					prv={Circle}
					title="Candidates for digital transformation"
					desc="Organizations with stable operations, large numbers of employees, and the need to control their processes through Software systems."

				/>
				<div className="horizontal_dotted_line"/>
				<UserSegmentCard
					prv={RightArrow}
					title="Technology companies that have done digital transformation"
					desc="The company's specialty is that a lot of development orders come from departments, IT departments are very busy, and large organizations that need to connect to their own systems."

				/>

				<h1>Recommended solutions</h1>

			</div>

			<div className="SolutionContainer Section">
				<div className="SolutionContent">

					<svg id="mainSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">

						<defs>
							<g id="allBoxes">
								<rect x="62.92" y="231.96" width="100" height="100" />
								<rect x="185.14" y="231.96" width="100" height="100" rx="50" />
								<rect x="305.14" y="231.96" width="100" height="100" />
								<rect x="425.14" y="231.96" width="100" height="100" rx="50" />
								<rect x="545.14" y="231.96" width="100" height="100" />
								<rect x="665.14" y="231.96" width="100" height="100" rx="50" />

							</g>
						</defs>

						<use xlinkHref="#allBoxes" />
						<g id="ref">
							<use xlinkHref="#allBoxes" x="-0" y="100" opacity="0.1" />
						</g>
					</svg>
					<h1>
						Low code solution
					</h1>

					<h2>
						A solution to create a base system without micro-coding, mapping and mapping business activities in a process-based manner according to international standards
					</h2>

					<br/>
					<br/>

					<h1>
						Bizpro ENGINE 1.0 solution
					</h1>

					<h2>
						BizPro engine is a solution for automating processes, creating inter-process coordination, and transferring resource management
					</h2>

					<br/>
					<br/>
				</div>
			</div>

			<div className="DesignContainer WorkItemAnimation">

				<h1>Lowcode and Bizpro engine features</h1>
				<motion.div
					className="Designs"
					layout
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					{filtered.map((item) => {
						return (
							<motion.div
								layout
								className="item itemAnimation"
								key={item.id}
							>


								<div className="ContentContainer">
									<h4 >{item.title}</h4>
									<h5>{item.tag}</h5>
								</div>
								<img src={item.Prv} />


							</motion.div>
						);
					})}
				</motion.div>

			</div>


			<div className="UserSegmentTitle">
				<h1 ref={GDWorksTitle}>Who needs Bizpro?</h1>
			</div>
		</StyledContainer>
	);
}

const StyledContainer = styled.div`
  padding: 25px 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
	content: "";
	height: 100%;
	width: 100%;
	margin-top: 20%;
	background-color: var(--White-Color);
	position: absolute;
	right: 0;
	z-index: -9;
	@media (max-width: 768px) {
	  height: 95%;
	}
  }

  .DesignContainer {
	transform: translateY(330px);
	height: 100%;
	width: 100%;

	@media (max-width: 768px) {
	  margin: 10px 0%;
	  padding: 10px 0%;
	}


	h1 {
	  font-family: Inter, sans-serif;
	  font-weight: 900;
	  color: #ffffff;
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  overflow: hidden;
	  font-size: 24px;
	  padding: 20px;
	  @media (max-width: 768px) {
		font-size: 16px;
	  }
	}

	.Designs {
	  --auto-grid-min-size: 16rem;
	  display: grid;
	  grid-template-columns: repeat(
			  auto-fill,
			  minmax(var(--auto-grid-min-size), 1fr)
	  );
	  grid-gap: 1rem;

	  column-gap: 10px;
	  row-gap: 10px;
	  justify-items: center;
	  justify-content: center;
	  align-items: center;
	  align-content: center;
	  grid-auto-flow: row;
	  padding: 10px;
	  ;

	  .item {
		width: 100%;
		height: 350px;
		background-color: white;
		border-radius: 5px;
		transition: 0.25s ease-in-out;
		overflow: hidden;
		position: relative;
		background-position: center;
		background-size: cover;
		cursor: pointer;
		display: inline-block;
		align-items: center;
		justify-content: center;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;


		img{
		  width: 100%;
		  height: 100%;
		  position: absolute;
		  top: 50%;
		  opacity: 0.1;
		}

		.ContentContainer{
		  position: absolute;
		  top: 10%;
		  z-index: 1;
		  h4 {
			font-family: Inter, sans-serif;
			font-weight: 900;
			color: var(--black-color);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			font-size: 20px;
			padding: 20px;
			@media (max-width: 768px) {
			  font-size: 20px;
			}
		  }



		  h5 {
			font-family: Inter, sans-serif;
			font-weight: 200;
			color: var(--black-color);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			font-size: 18px;
			padding: 20px;
			@media (max-width: 768px) {
			  font-size: 18px;
			}
		  }
		}


	  }
	}
  }

  .UserSegmentContainer {
	transform: translateY(130px);
	padding: 25px 3%;
	width: 82%;
	border-radius: 10px;
	margin-bottom: 40px;
	background-color: var(--White-Color);

	.horizontal_dotted_line {
	  border-bottom: 3px dotted white;
	  opacity: 0.2;
	}

	h1 {
	  font-family: Inter, sans-serif;
	  font-weight: 900;
	  color: #ffffff;
	  width: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  overflow: hidden;
	  font-size: 2.5rem;
	  padding: 20px;
	  @media (max-width: 768px) {
		font-size: 1.9rem;
	  }
	}

	@media (max-width: 768px) {
	  width: 94%;
	  border-radius: 5px;
	}

  }

  .SolutionContainer {
	transform: translateY(330px);
	height: 100%;
	width: 100%;
	padding: 10px 9%;
	background: no-repeat;
	background-size: contain;
	background-position: right;
	background-image: url(${SolutionBg});

	@media (max-width: 768px) {
	  margin: 10px 0%;
	  padding: 10px 0%;
	}

	.SolutionContent {
	  position: relative;
	  width: 80%;
	  background: rgb(246 246 246 / 90%);
	  border-radius: 16px;
	  box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
	  padding: 2vh;
	  border: 1px solid rgb(221 221 226);
	  left: 10%;
	  right: 20%;

	  h1 {
		color: var(--black-color);
		font-family: Inter, sans-serif;
		font-weight: 900;
		font-size: 24px;
		line-height: 1.8rem;
	  }

	  h2 {
		color: var(--black-color);
		font-family: Inter, sans-serif;
		font-weight: 200;
		font-size: 18px;
		line-height: 1.8rem;
	  }


	  svg {
		width: 100px;
		height: 100%;
		visibility: hidden;

	  }

	  #allBoxes rect:nth-child(3) {
		fill: var(--black-color);
	  }

	  #allBoxes rect:nth-child(4) {
		fill: var(--black-color);
	  }

	  #allBoxes rect:nth-child(5) {
		fill: var(--black-color);
	  }

	  #allBoxes rect:nth-child(6) {
		fill: var(--black-color);
	  }

	  #allBoxes rect:nth-child(7) {
		fill: var(--black-color);
	  }

	  #allBoxes rect:nth-child(2) {
		fill: var(--black-color);
	  }

	  #allBoxes rect:nth-child(1) {
		fill: var(--black-color);
	  }



	}

  }

  .LowCodeSolution{


  }

  .UserSegmentTitle {
	position: absolute;
	height: auto;
	font-size: 30px;

	writing-mode: vertical-rl;
	display: inline-block;
	font-family: Inter, sans-serif;
	top: 25px;
	left: 3%;
	@media (max-width: 768px) {
	  display: none;
	}

	h1 {
	  color: var(--MainColor);
	  font-weight: 100;
	}
  }




  @media (max-width: 768px) {
	flex-direction: column;
	justify-content: initial;
	align-items: initial;

	.left {
	  width: 100%;
	}

	.right {


	  width: 100%;
	  margin-left: 0px;
	  padding: 10px;
	  position: static;


	  h1 {
		font-size: 15px;
		text-align: left;

	  }
	}
  }


  .right {
	position: absolute;
	width: 50%;
	background: rgb(246 246 246 / 90%);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
	padding: 2vh;
	border: 1px solid rgb(221 221 226);
	left: 10%;

	h1 {
	  color: var(--black-color);
	  font-family: Inter, sans-serif;
	  font-weight: 300;
	  font-size: 20px;
	  opacity: 0.7;
	  line-height: 1.8rem;
	  transform: translateX(8vw);
	  opacity: 0;
	}
  }

  @media (max-width: 768px) {
	flex-direction: column;
	justify-content: initial;
	align-items: initial;

	.left {
	  width: 100%;
	}

	.right {


	  width: 100%;
	  margin-left: 0px;
	  padding: 10px;
	  position: static;


	  h1 {
		font-size: 15px;
		text-align: left;

	  }
	}
  }
`;

const UserSegmentCard = ({
							 prv,
							 disabled,
							 title,
							 desc
						 }) => {
	return (
		<StyledUserSegmentCard prv={prv} className="DevWorkItemAnimate">
			<div className="RightUserSegmentCard">
				<h2>
					{title}
					<span className="Span">{disabled ? `(In developmenet)` : ""}</span>
				</h2>
				<p>{desc}</p>

			</div>

			<div className="LeftUserSegmentCard">
				<img src={prv} width="80" height="auto"/>
			</div>

		</StyledUserSegmentCard>
	);
};
const StyledUserSegmentCard = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
  opacity: 0;
  transform: translateY(130px);
  margin-top: 10vh;


  @keyframes anim-ss {
	0% {
	  background-position: 0%;
	}
	100% {
	  background-position: 100%;
	}
  }
  @media (max-width: 768px) {
	flex-direction: column;

	border-left: 1px solid rgba(171, 168, 165, 0.2);
	border-right: 1px solid rgba(171, 168, 165, 0.2);

	margin-bottom: 40px;
  }



  .LeftUserSegmentCard {
	width: 30%;
	height: auto;

	img {
	  display: block;
	  margin-left: auto;
	  margin-right: auto;
	  width: 80px;
	}

	@media (max-width: 768px) {
	  width: 100%;
	  border-radius: 8px;
	  border-bottom-left-radius: 0px;
	  border-bottom-right-radius: 0px;
	}
  }

  .RightUserSegmentCard {
	width: 70%;
	margin-left: 40px;
	display: flex;

	flex-direction: column;
	@media (max-width: 768px) {
	  width: 100%;
	  margin-left: 0px;
	  margin-bottom: 10px;
	}


	h2 {
	  font-family: Inter, sans-serif;
	  font-weight: 600;
	  font-size: 24px;
	  margin-bottom: 10px;
	  color: #ffffff;
	  @media (max-width: 768px) {
		text-align: center;
	  }
	}

	p {
	  font-family: Inter, sans-serif;
	  font-size: 18px;
	  font-weight: 200;
	  margin-bottom: 25px;
	  color: #ffffff;

	  @media (max-width: 768px) {
		text-align: center;
	  }
	}

	.List {
	  display: grid;
	  grid-template-columns: auto auto auto auto;
	  grid-template-rows: auto;
	  justify-content: left;
	  grid-row-gap: 10px;
	  @media (max-width: 768px) {
		grid-template-columns: auto auto;
		margin-bottom: 20px;
		justify-content: center;
	  }

	  .tag {
		background-color: #ece7e3;
		padding: 5px 10px;
		border-radius: 5px;
		margin-right: 15px;
		@media (max-width: 768px) {
		  border-radius: 4px;
		}

		h3 {
		  font-family: "Poppins";
		  color: #a09d9a;
		  @media (max-width: 768px) {
			font-size: 15px;
		  }
		}
	  }
	}

	.Buttons {
	  margin-left: auto;
	  margin-top: auto;
	  margin-top: 15px;
	  display: flex;
	  flex-direction: row;

	  @media (max-width: 768px) {
		width: 100%;
		margin-left: 0;
		margin-top: 0;
		justify-content: center;

		position: absolute;
		bottom: -15px;
	  }

	  .linkBut {
		text-decoration: none;

		.disabled {
		  cursor: not-allowed;
		  color: #797774;
		}

		button {
		  font-family: "Lemon/Milk light", sans-serif;
		  padding: 5px 10px;
		  border-radius: 5px;
		  border: 1px solid rgba(160, 157, 154, 0.4);
		  background-color: transparent;
		  color: #10101a;
		  font-size: 1rem;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  flex-direction: row;
		  cursor: pointer;

		  .InnerIcons {
			margin-left: 5px;
		  }
		}

		button:first-child {
		  margin-right: 6px;
		}
	  }
	}
  }


`;

export default WorksContainer;
