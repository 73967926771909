import React, { useEffect } from "react";
import styled from "@emotion/styled";
import AboutPic from "../../../Media/About/About.jpg";
import Bg3 from "../../../Media/Statistic/bg3.svg";
//Gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function About() {
  useEffect(() => {
	  gsap
		  .timeline()
		  .to("[data-animation='gsap'] .box", {
			  duration: 2.5,
			  yoyo: true,
			  yoyoEase: true,
			  y: -50,
			  ease: "power1.inOut",
			  stagger: {
				  each: 0.5,
				  from: "end",
				  repeat: -1
			  }
		  })
		  .time(5.5);






  }, []);

  return (
    <StyledAbout data-scroll-section id="Value">



		<div className="left">

			<div className="group" data-animation="gsap">
				<img className="box" src={Bg3}></img>
				<img className="box" src={Bg3}></img>
				<img className="box" src={Bg3}></img>
			</div>

		</div>

		<div className="right">
			<div className="StatisticContainer">


				<div className="StatisticContent">

					<div className="SolutionLeft">
					<h1>
						81%
					</h1>
					</div>

					<div className="SolutionRight">
						<h1>
							Less Development Time
						</h1>

						<h2>
						Research shows that developing systems with low-code and no-code solutions can be developed up to 90% faster than traditional development methods.
						</h2>
					</div>

				</div>
			</div>

			<div className="StatisticContainer">


				<div className="StatisticContent">

					<div className="SolutionLeft">
						<h1>
							70%
						</h1>
					</div>

					<div className="SolutionRight">
						<h1>
							Easy to Learn
						</h1>

						<h2>
						70% of users who have never used Low-code before learned the solution in less than a month and expect to continue using it.
						</h2>
					</div>

				</div>
			</div>

			<div className="StatisticContainer">


				<div className="StatisticContent">

					<div className="SolutionLeft">
						<h1>
							65%
						</h1>
					</div>

					<div className="SolutionRight">
						<h1>
							Future of Low-code
						</h1>

						<h2>
						According to Gartner, the world's top management consulting service provider, by 2024, 65% of all IT developments in the world will be done with low-code solutions.
						</h2>
					</div>

				</div>
			</div>
		</div>

    </StyledAbout>
  );
}

const StyledAbout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  background-size: cover;
  align-items: center;
  justify-content: center;

  .left {
	width: 100%;
	height: 100%;
	position: absolute;
	transform: translateX(-5vw);

	.group {
	  display: flex;
	  flex-direction: column-reverse;

	  &::before {
		color: white;
		margin-top: 30px;
		text-align: center;
	  }

	  &[data-animation="css"]::before {
		content: "CSS";
	  }

	  &[data-animation="gsap"]::before {
		content: "GSAP";
	  }
	}

	.box {
	  height: 240px;
	  width: 240px;
	  z-index: 1;


	  & + & {
		margin-bottom: -50px;
	  }


	  [data-animation="css"] & {
		animation: bounce 5s ease-in-out infinite;

		&:nth-of-type(1) {
		  animation-delay: -4s;
		}
		&:nth-of-type(2) {
		  animation-delay: -4.5s;
		}
	  }

	  @keyframes bounce {
		50% {
		  transform: translate3d(0px, -50px, 0px) rotateX(50deg) rotateZ(40deg);
		}
	  }
	}
  }

  .right {


	.StatisticContainer {
	  height: 100%;
	  width: 100%;
	  padding: 10px 9%;

	  @media (max-width: 768px) {
		margin: 10px 0%;
		padding: 10px 0%;
	  }

	  .StatisticContent {
		position: relative;
		width: 80%;
		display: flex;
		background: rgb(246 246 246 / 90%);
		border-radius: 16px;
		box-shadow: 0 4px 30px rgb(0 0 0 / 5%);
		padding: 2vh;
		border: 1px solid rgb(221 221 226);
		left: 10%;
		right: 20%;


		.SolutionLeft {
		  position: relative;

		  margin-right: 2vh;

          h1 {
            color: var(--black-color);
            font-family: Inter, sans-serif;
            font-weight: 300;
            font-size: 42px;
            line-height: 1.8rem;
			margin-top: 40%;

          }
        }

		.SolutionRight{
		  display: inline-block;


		  h1 {
			color: var(--black-color);
			font-family: Inter, sans-serif;
			font-weight: 900;
			font-size: 24px;
			line-height: 1.8rem;
		  }

		  h2 {
			color: var(--black-color);
			font-family: Inter, sans-serif;
			font-weight: 200;
			font-size: 18px;
			line-height: 1.8rem;
		  }
		}


	  }

	}


  }



  @media (max-width: 768px) {
	flex-direction: column;
	justify-content: initial;
	align-items: initial;

	.left {
	  width: 100%;
	}

	.right {


		width: 100%;
	  margin-left: 0px;
	  padding: 10px;


	  h1 {
		font-size: 15px;
		text-align: left;

	  }
	}
  }

`;

export default About;
