// Preview
import Edit1 from "../../../Media/Portfolio/Preview/Edit1.webp";
import Edit2 from "../../../Media/Portfolio/Preview/Edit2.webp";
import Edit3 from "../../../Media/Portfolio/Preview/Edit3.webp";
import Edit4 from "../../../Media/Portfolio/Preview/Edit4.webp";
import Edit5 from "../../../Media/Portfolio/Preview/Edit5.webp";
import Edit6 from "../../../Media/Portfolio/Preview/Edit6.webp";
import Edit7 from "../../../Media/Portfolio/Preview/Edit7.webp";
import Edit8 from "../../../Media/Portfolio/Preview/Edit8.webp";
import Edit9 from "../../../Media/Portfolio/Preview/Edit9.webp";
import Edit10 from "../../../Media/Portfolio/Preview/Edit10.webp";

// Url
import Edit1_ from "../../../Media/UserSegment/Icon1.svg";
import Edit2_ from "../../../Media/UserSegment/Icon2.svg";
import Edit3_ from "../../../Media/UserSegment/Icon3.svg";
import Edit4_ from "../../../Media/UserSegment/Icon4.svg";
import Edit5_ from "../../../Media/UserSegment/Icon5.svg";
import Edit6_ from "../../../Media/UserSegment/Icon6.svg";
import Edit7_ from "../../../Media/UserSegment/Icon7.svg";


//Data
export const Data = [
  {
    id: 1,
    Prv: Edit1_,
    Url: Edit1_,
    title: "App Builder",
    tag: "The development solution works automatically on Cross framework, Web and Mobile systems",
  },
  {
    id: 2,
    Prv: Edit2_,
    Url: Edit2_,
    title: "Process Designer",
    tag: "BPMN 2.0 or international ISO standard process mapping is done on the system, Back-end coding is written without human intervention",
  },
  {
    id: 3,
    Prv: Edit3_,
    Url: Edit3_,
    title: "Form Designer",
    tag: "Dynamic resources, with the ability to create, manage, change, and monitor dynamic forms of resource allocation for each mapped process function",
  },{
		id: 4,
		Prv: Edit4_,
		Url: Edit4_,
		title: "Dashboards",
		tag: "Dynamic chart, resource management, system, operation progress and process control",
	},
  {
    id: 5,
    Prv: Edit5_,
    Url: Edit5_,
    title: "Integrations",
    tag: "HTTP Api or all types of apis that work in connection with systems other than the system are available, and it is also possible to run processes in coordination with apis from other systems.",
  },
  {
    id: 6,
    Prv: Edit6_,
    Url: Edit6_,
    title: "Reports & Analytics",
    tag: "Dynamic report works by automatically sending report information from the system, creating frequency, and assigning authorized persons to receive report information.",
  },
  {
    id: 7,
    Prv: Edit7_,
    Url: Edit7_,
    title: "External portals",
    tag: "It is possible to simultaneously manage multi-tenant resources",
  }
];
