import React, {useEffect} from 'react'
import styled from 'styled-components'

//Gsap
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function SectionTitle({bg, color, text}) {
  useEffect(()=>{
    ScrollTrigger.batch(".TtleSectionAnimation", {
      interval: 0.1,
      batchMax: 3,
      onEnter: batch =>     gsap.to(batch,  {autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true}),
      onLeave: batch =>     gsap.set(batch, {autoAlpha: 0, y: -100,             overwrite: true}),
      onEnterBack: batch => gsap.to(batch,  {autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true}),
      onLeaveBack: batch => gsap.set(batch, {autoAlpha: 0, y: 100,              overwrite: true})
    });
  },[])
  return (
    <StyledSectionTitle bg={bg} color={color} data-scroll-section className='TtleSectionAnimation'>
      <h1>{text}</h1>
    </StyledSectionTitle>
  )
}

const StyledSectionTitle = styled.div`

  margin-top: 15%;

   h1{
    font-family: Inter, sans-serif;
	 font-weight: 900;
    color :#051C2C;
     width : 100%;
     display : flex;
     align-items : center;
     justify-content : center;
     overflow : hidden;
     font-size : 2.5rem;
     padding : 20px;
     @media(max-width: 768px){
       font-size : 1.9rem;
     }
   }


   `

export default SectionTitle
