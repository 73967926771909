import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ioi from "../../../Media/Skills/ioi.png";
import iot from "../../../Media/Skills/iot.png";
import ioc from "../../../Media/Skills/ioc.png";
import ios from "../../../Media/Skills/ios.png";
import pattern from "../../../Media/pattern.png";


gsap.registerPlugin(ScrollTrigger);

function SkillsTitles() {

	const SkillsTexts = useRef(null);
	useEffect(() => {
		const el = SkillsTexts.current;

		gsap.to(el, {
			scrollTrigger: {
				trigger: el,
				start: "top bottom",
				end: "bottom top",
				scrub: 0.5
			},
			x: "-25%",
			ease: "none"
		})
	}, [])

	return (


		<StyledSkillsTitles data-scroll-section>

			<h3>
				<p>Our <b>Partners</b></p>
			</h3>

			<div className="marqueeContainer">
				<div class="marqueeTrack" ref={SkillsTexts}>
					<div class="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={iot}
							className="spinText"
						/>

						<div class="marqueeDot"></div>
					</div>

					<div class="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={ios}
							className="spinText"
						/>
						<div class="marqueeDot"></div>
					</div>

					<div class="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={ioi}
							className="spinText"
						/>
						<div class="marqueeDot"></div>
					</div>

					<div className="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={ioc}
							className="spinText"
						/>
						<div className="marqueeDot"></div>
					</div>
					<div className="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={iot}
							className="spinText"
						/>

						<div className="marqueeDot"></div>
					</div>

					<div className="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={ios}
							className="spinText"
						/>
						<div className="marqueeDot"></div>
					</div>

					<div className="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={ioi}
							className="spinText"
						/>
						<div className="marqueeDot"></div>
					</div>

					<div className="marqueeItem">

						<img
							width="auto"
							height="100px"
							src={ioc}
							className="spinText"
						/>
						<div className="marqueeDot"></div>
					</div>

				</div>
			</div>
		</StyledSkillsTitles>
	)
}

const StyledSkillsTitles = styled.div`

  background-image: url(${pattern});
  margin-bottom : 5vh;
  padding-bottom: 3vh;
  padding-top: 3vh;

  h3 {
	font-family: Inter,sans-serif;
	font-weight: 200;
	color:black;
	font-size: 22px;
	margin-bottom: 5vh;
	text-align: center;
	@media (max-width: 768px) {
	  font-size: 20px;
	  line-height: 95%;
	}
  }

  .marqueeContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	.marqueeTrack {
	  width: auto;
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
	  flex-direction: row;
	  flex-wrap: nowrap;
	  flex-shrink: 0;


	  .marqueeItem {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;

		p {
		  color: #26263e;
		}


		.marqueeDot {
		  width: 1vw;
		  height: 1vw;
		  margin-bottom: 0.5vw;
		  background-color: #333353;
		  border-radius: 1vw;
		  margin: 0 4vw 0.5vw 4vw;
		}
	  }
	}
  }
`

export default SkillsTitles
